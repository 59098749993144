import Vue from 'vue';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import JwtManager from './jwtManager';
import moment from 'moment';
import { AuthController } from '@/plugins/typings/UIApiControllers/Authentication/AuthController';
import store from '@/store';
import { sha256 } from 'js-sha256';
import { detect, isMobile } from 'get-browser';

const isImpersonatedUser = (): boolean => JwtManager.refreshToken === null;
export interface ICloudUser extends IUser {
  consumerPackageId: string;
  shard: string;
  version: string;
  customerCode: string;
}
const setInsightsInfo = (user: IUser): void => {
  if (isImpersonatedUser()) {
    return;
  }
  const { appInsights } = window as any;
  if (appInsights) {
    appInsights.trackPageView({ name: '', properties: { 'Screen Resolution': `${window.screen.width}x${window.screen.height}` } });
  }
};

const setGainsightInfo = async (user: ICloudUser): Promise<void> => {
  if (isImpersonatedUser()) {
    return;
  }
  const anonymousData = (data: string | number, placeholder: string | number): string | number => {
    if (store.state.cookiePreferences?.C0006) return data;
    return placeholder;
  };
  const { aptrinsic } = window as any;
  if (aptrinsic) {
    const c = {
      id: sha256(user.userName), // Required for logged in app users
      CRM_username: anonymousData(user.userName, '********'),
      email: anonymousData(user.email, '****@****.**'),
      firstName: anonymousData(user.firstName, '********'),
      lastName: anonymousData(user.lastName, '********'),
      CRM_isActiveUser: true,
      CRM_manager: anonymousData(user.myBoss.toString(), '*****'),
      CRM_timeZone: user.timeZoneId,
      CRM_isAdmin: user.isAdmin,
      systemType: 'internal',
      CRM_groupId: anonymousData(user.userGroupId, '*******'),
      CRM_role: anonymousData(user.role, '******'),
      CRM_avatar: anonymousData(user.avatar, null),
      CRM_culture: anonymousData(user.culture, '**'),
      phone: anonymousData(user.phone, '***-*** ****'),
      CRM_score: user.score,
      stateCode: user.state,
      city: anonymousData(user.city, '*********'),
      title: anonymousData(user.title, '*********'),
      CRM_browser: detect(),
      CRM_isMobile: isMobile(),
      CRM_browser_width: window.screen.width,
      CRM_browser_height: window.screen.height,
      CRM_orientation: window.screen.width > window.screen.height ? 'landscape' : 'portrait',
      CRM_frontend: 'Vue',
    };
    const authController = new AuthController(Vue.axios);
    const stats = (await authController.Stats()) ?? {};
    const u = {
      id: sha256(user.customerHash),
      name: anonymousData(user.customerName, '********'),
      CRM_createdDate: anonymousData(moment(user.customerCreatedDate).unix(), null),
      CRM_expirationDate: anonymousData(moment(user.expirationDate).unix(), null),
      CRM_numberOfUsers: anonymousData(user.maxUsers, null),
      CRM_stage: user.trial ? 'trial' : 'payed',
      CRM_status: 'active',
      CRM_logo: anonymousData(user.companyPhoto, null),
      CRM_licence: anonymousData(user.licence, '***-***-***'),
      CRM_city: anonymousData(user.customerCity, '********'),
      CRM_stateCode: anonymousData(user.customerState, '**'),
      CRM_version: user.version,
    };
    for (const key in stats) {
      if (Object.prototype.hasOwnProperty.call(stats, key)) {
        const element = stats[key];
        u[`CRM_${key}`] = element;
      }
    }
    aptrinsic('identify', c, u);
    if (user.trial) {
      // aptrinsic('lead', user.hash, {
      //   ...c,
      //   ...u,
      //   id: `${user.customerHash}_${user.hash}`,
      // });
    }
  }
};

const setGainsightEvent = (event: string, payload: any): void => {
  if (isImpersonatedUser()) {
    return;
  }
  const { aptrinsic } = window as any;
  if (aptrinsic) {
    aptrinsic('track', event, payload);
  }
};

const trackUser = (user: IUser): void => {
  Vue.chat.user(user as ICloudUser);
  setInsightsInfo(user);
  setGainsightInfo(user as ICloudUser);
};
const resetUser = (): void => {
  const { aptrinsic } = window as any;
  if (aptrinsic) {
    aptrinsic('reset');
  }
};
const trackEvent = (event: string, payload: any): void => {
  setGainsightEvent(event, payload);
};

export default {
  trackUser,
  setInsightsInfo,
  setGainsightInfo,
  resetUser,
  trackEvent,
};
