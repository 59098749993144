// the Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '../theme/index.css';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Vue from 'vue';
import initializers from './initializers';
import pageTitle from './initializers/page-title';
import App from './App.vue';
import { routerInitializer } from './router/index';
import { use, CookieSessionJwtManager } from './utils/jwtManager';
import './registerServiceWorker';
// import runtime from 'serviceworker-webpack-plugin/lib/runtime';
import store from './store';

require('utils/consoleHide');
require('moment/locale/it');
require('moment/locale/es');
require('moment/locale/fr');
require('moment/locale/de');
require('moment/locale/ro');
/*
if ('serviceWorker' in navigator) {
  runtime.register();
} */
fetch('/config.json')
  .then((r) => r.json())
  .then((config) => {
    const { baseUrl } = config;
    // modificare la config in ../config/config.dev/prod.js
    // 'http://localhost';
    // cloud.tustena.testserver.digita.it
    Vue.config.ignoredElements = ['cookies-dialog'];
    Vue.config.productionTip = config.productionTip;
    Vue.config.performance = config.performance;
    if (config.cookie) {
      use(new CookieSessionJwtManager());
    }
    const init = async () => {
      if (config.applicationInsights) {
        const appInsights = new ApplicationInsights({
          config: {
            connectionString: config.applicationInsights,
          },
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
        (window as any).appInsights = appInsights;
      }
      const initResult = initializers(baseUrl, config.maxRequests);
      store.commit('setBaseUrl', baseUrl);
      store.commit('setConfig', config);
      const router = routerInitializer.init(store);
      await store.dispatch('init', router);
      const anonymous = await import('./modules/anonymous');
      anonymous.init();
      pageTitle(router, store.getters.brand.name ?? 'TeamSystem CRM in Cloud');
      // eslint-disable-next-line no-new
      new Vue({
        store,
        router,
        i18n: initResult.i18n,
        el: '#app',
        template: '<App/>',
        components: { App },
      });
      await store.dispatch('loadModules', router);
    };

    init();
  });
