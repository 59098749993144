<template>
  <div>
    <el-row align="middle" justify="center" :gutter="10">
      <el-col :sm="24" :md="18">
        <div class="text text-1x text-primary">
          <router-link :to="{ name: $routeNames.tustenaCompany }">
            <t-icon-label icon="el-icon-fa-user-clock fas icon-2x">
              <div class="m-l-q">
                {{ $t(expirationDays > 0 ? 'tustenaCompany.licence.info' : 'tustenaCompany.licence.expired', {
                  expirationDays, expirationDate: $moment(user.expirationDate).format('LL')
                }) }}
                <div class="text-danger m-t-q v-middle" v-if="isInBlackFriday">
                  👉🏻 Approfitta del
                  <b>Black Friday</b> e ottieni il
                  <b>50%</b> di sconto
                  <span class="text text-info">
                    (fino al 30/11)
                  </span>
                  🚀
                </div>
                <div class="text-danger m-t-q v-middle" v-if="isInSDSBlackFriday">
                  👉🏻 Aprovecha el
                  <b>Black Friday</b> y consigue un
                  <b>40%</b> de descuento 🚀
                </div>
              </div>
            </t-icon-label>
          </router-link>
        </div>
      </el-col>
      <el-col class="text-right" :sm="24" :md="6">
        <t-my-area-store-button>{{ $t('licence.buy') }}</t-my-area-store-button>
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { ITustenaCompanyDTO } from 'dto/ITustenaCompanyDTO';
import TExpirationDateAlert from './ExpirationDateAlert.vue';

// tslint:disable-next-line:variable-name
const TustenaCompanyAction = namespace('tustenaCompany').Action;
// tslint:disable-next-line:variable-name
const TustenaCompanyGetter = namespace('tustenaCompany').Getter;
// tslint:disable-next-line:variable-name
const AccountAction = namespace('account').Action;
// tslint:disable-next-line:variable-name
const AccountGetter = namespace('account').Getter;
@Component({
  name: 'Licence',
  components: {
    TExpirationDateAlert,
  },
})
export default class Licence extends Vue {
  @Getter('user') user: IUser;

  @Getter isInBlackFriday: boolean;

  @Getter isInSDSBlackFriday: boolean;

  get expirationDays() {
    const expirationDate = this.$moment(this.user.expirationDate);
    return expirationDate.diff(this.$moment(), 'days');
  }
}
</script>
